import gql from "graphql-tag";

const MUTATION_INITIATE_SYNC_ACCOUNT = gql`
  mutation initiateSyncAccount(
    $account: InitiateSyncAccountInput!
    $metadata: MetaDataInput!
  ) {
    initiateSyncAccount(account: $account, metadata: $metadata) {
      result {
        conversation_id
        event_ids
      }
      status
    }
  }
`;

export default MUTATION_INITIATE_SYNC_ACCOUNT;
