import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import NotificationService from "@/core/services/notification.service";
import DialogService from "@/core/services/dialog.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import {
  GET_ACCOUNT_IN_ALL_PLATFORMS,
  GET_ACCOUNT_IN_ALL_PLATFORMS_OLD
} from "@/core/services/graphql/queries/dynamics365/account";
import MUTATION_INITIATE_SYNC_ACCOUNT from "@/core/services/graphql/mutation/dynamics365/account";
import UtilService from "@/core/services/util.service";
import DocxGeneratorService from "@/core/services/docxgenerator.service";
import {
  ADD_ACCOUNT_SYNCED_HISTORY,
  CLEAR_ACCOUNT_SYNCED_HISTORY
} from "@/store/modules/accountSyncedHistory.module";
import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "CommandBaseAccount",
  data: () => ({
    description_list: [],
    account: null,
    formValid: false,
    account_number: null,
    accountNumberRules: [
      v => !!v || "Required.",
      v =>
        /^ACC-+\d{10}/.test(v) ||
        "Account number must be valid. Ex: ACC-0000006878"
    ],
    form2Valid: false,
    account_number_old: null,
    accountNumberOldRules: [v => !!v || "Required."],
    isLoading: false
  }),
  apollo: {
    account: {
      query: () => GET_ACCOUNT_IN_ALL_PLATFORMS,
      skip() {
        return true;
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      result({ data, networkStatus }) {
        if (networkStatus == 7) {
          this.account = data.account;
          this.updateDescriptionList();
        }
      }
    },
    accounts: {
      query: () => GET_ACCOUNT_IN_ALL_PLATFORMS_OLD,
      skip() {
        return true;
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      result({ data, networkStatus }) {
        if (networkStatus == 7) {
          this.account = data.accounts[0];
          this.updateDescriptionList();
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Integration Office", route: "navigation-menu" },
      { title: "Command Base Account", route: "command-base-account" }
    ]);

    this.$store.dispatch(CLEAR_ACCOUNT_SYNCED_HISTORY);
  },
  computed: {
    ...mapGetters(["getAccountSyncedHistory"])
  },
  methods: {
    changeValue() {
      this.account_number = null;
      this.$refs.form.resetValidation();
    },
    updateDescriptionList() {
      if (this.account) {
        if (!this.account.servicenow_account) {
          this.account.servicenow_account = {};
        }

        this.description_list = [
          {
            description: "Id",
            dynamicsCE: this.account.accountid,
            servicenow: this.account.servicenow_account.sys_id,
            isComparable: false
          },
          {
            description: "Account number",
            dynamicsCE: this.account.accountnumber,
            servicenow: this.account.servicenow_account.number,
            isComparable: true
          },
          {
            description: "Old account number",
            dynamicsCE: this.account.sen_accountnumberold,
            servicenow: this.account.servicenow_account.u_old_account_number,
            isComparable: true
          },
          {
            description: "Name",
            dynamicsCE: this.account.name,
            servicenow: this.account.servicenow_account.name,
            isComparable: true
          },
          {
            description: "Address",
            dynamicsCE: this.account.address1_line1,
            servicenow: this.account.servicenow_account.street,
            isComparable: true
          },
          {
            description: "Postal code",
            dynamicsCE: this.account.address1_postalcode,
            servicenow: this.account.servicenow_account.zip,
            isComparable: true
          },
          {
            description: "City",
            dynamicsCE: this.account.address1_city,
            servicenow: this.account.servicenow_account.city,
            isComparable: true
          },
          {
            description: "Country",
            dynamicsCE: this.account.address1_country,
            servicenow: this.account.servicenow_account.country,
            isComparable: true
          },
          {
            description: "Phone",
            dynamicsCE: this.account.telephone1,
            servicenow: this.account.servicenow_account.phone,
            isComparable: true
          },
          {
            description: "Strategic Customer",
            dynamicsCE:
              this.account.sen_strategiccustomer == 284400000
                ? "A. Strategic"
                : this.account.sen_strategiccustomer == 284400001
                ? "B. Non-Strategic"
                : "",
            servicenow: this.account.servicenow_account
              .u_contract_value_indicator,
            isComparable: true
          },
          {
            description: "Operational Team",
            dynamicsCE: this.account.opsteam_name,
            servicenow: this.account.servicenow_account.u_customer_team,
            isComparable: true
          },
          {
            description: "Created",
            dynamicsCE: dayjs(this.account.createdon).format(
              "DD-MM-YYYY HH:mm:ss"
            ),
            servicenow: dayjs(
              this.account.servicenow_account.sys_created_on,
              "DD MM YYYY HH:mm:ss"
            ).format("DD-MM-YYYY HH:mm:ss"),
            isComparable: false
          },
          {
            description: "Updated",
            dynamicsCE: dayjs(this.account.modifiedon).format(
              "DD-MM-YYYY HH:mm:ss"
            ),
            servicenow: dayjs(
              this.account.servicenow_account.sys_updated_on,
              "DD MM YYYY HH:mm:ss"
            ).format("DD-MM-YYYY HH:mm:ss"),
            isComparable: false
          }
        ];
      }
    },
    searchByAccountNumber() {
      this.$refs.form.validate();
      if (this.formValid && this.account_number) {
        this.account = null;
        this.description_list = [];

        this.$apollo.queries.account.setVariables({
          account_number: this.account_number.trim()
        });

        this.$apollo.queries.account.skip = false;
        this.$apollo.queries.account.refetch();
      }
    },
    searchByAccountNumberOld() {
      this.$refs.form2.validate();
      if (this.form2Valid && this.account_number_old) {
        this.account = null;
        this.description_list = [];

        this.$apollo.queries.accounts.setVariables({
          account_number_old: this.account_number_old.trim()
        });

        this.$apollo.queries.accounts.skip = false;
        this.$apollo.queries.accounts.refetch();
      }
    },
    async initiateSync() {
      let message = `Are you sure you want to initiate a synchronisation of the account number <b>${this.account.accountnumber}</b>?`;

      if (
        await DialogService.confirm(this, "Confirmation", message, {
          manualControl: true
        })
      ) {
        this.$apollo
          .mutate({
            mutation: MUTATION_INITIATE_SYNC_ACCOUNT,
            variables: {
              account: {
                accountnumber: this.account.accountnumber
              },
              metadata: {
                conversation_id: ""
              }
            }
          })
          .then(res => {
            if (
              res.data.initiateSyncAccount &&
              res.data.initiateSyncAccount.result &&
              res.data.initiateSyncAccount.result.conversation_id
            ) {
              let conversation_id =
                res.data.initiateSyncAccount.result.conversation_id;
              let message = `Conversation Id </br> <b class="font-size-lg">${conversation_id}</b></br> </br>Please keep this Conversation Id as a reference for our development team if the sync does not work properly You can contact the development team on Slack: #sentia-dev-sala.`;
              DialogService.updateBodyContent(this, "Complete", message);
              setTimeout(() => {
                NotificationService.success(
                  "Successfully submitted your change"
                );
              }, 100);

              this.$store.dispatch(ADD_ACCOUNT_SYNCED_HISTORY, {
                account_number: this.account_number,
                conversation_id: conversation_id,
                created: dayjs().format("YYYY-MM-DD hh:mm:ss")
              });
            }
          });
      }
    },
    getWarningCssClass(item, type) {
      if (
        item.isComparable &&
        type == "Any" &&
        (item.dynamicsCE || item.servicenow) &&
        item.dynamicsCE != item.servicenow
      ) {
        return "text-danger";
      } else if (
        item.isComparable &&
        type == "ServiceNow" &&
        (item.dynamicsCE || item.servicenow) &&
        item.dynamicsCE != item.servicenow
      ) {
        return "text-danger";
      }
    },
    exportCsv() {
      if (this.account) {
        var headers = {
          description: "Description",
          dynamicsCE: "DynamicsCE",
          servicenow: "ServiceNow"
        };

        var fileName = `Control_Center_Account_${this.account.accountnumber}`;
        UtilService.exportCSVFile(headers, this.description_list, fileName);
      }
    },
    exportDocx() {
      if (this.account) {
        let title = `Control_Center_Account_${this.account.accountnumber}`;
        let templateUrl = "/templates/Control_Center_Account_template.docx";
        let data = {
          fields: this.description_list,
          date_from: dayjs().subtract(6, "M").format("MMM YYYY"),
          date_to: dayjs().format("MMM YYYY"),
          account_name: this.account.name,
          account_number: this.account.accountnumber,
          creation_date: dayjs().format("DD/MM/YYYY")
        };

        DocxGeneratorService.generate({
          title: title,
          templateUrl: templateUrl,
          data: data
        });
      }
    }
  }
};
